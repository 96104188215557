@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

/* $fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss'; */



/* Safari 4.0 - 8.0 */
@-webkit-keyframes pulse {
  0%   {background-color: #42f453;}
  50%  {background-color: blue;}
  100% {background-color: #42f453;}
}

/* Standard syntax */
@keyframes pulse {
    
  0%   {background-color: #42f453;}
  50%  {background-color: blue;}
  100% {background-color: #42f453;}
}
