table.codeInputs{
    width: 100% !important;
    text-align: center;
    border: 1px solid #eeeeee;
}



.inputFields {
    display: flex !important;
    padding: 0 0 0 6em;
}

